<template>
  <div class="senate_page">
    <div class="banner_content">
      <div class="nar_content">
        <h2>JCI SENATE</h2>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide.</p>
      </div>
      <topNavBar />
    </div>
    <div class="recognizes">
      <img src="../../assets/pc_img/mobile_banner.png" style="
                    object-fit: inherit;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                  " alt="" />
      <div class="content">
        <h3>JCI recognizes outstanding leaders.</h3>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide. JCI Senators display determination and leadership and are encouraged to stay
          connected and provide support throughout the entirety of their lives.</p>
        <div class="rec_btn">
          <div class="btn_com" @click="toDetail">DIRECTORY</div>
          <div class="btn_com" @click="$router.push('/apply')">GIVE A JCI SENATORSHIP</div>
        </div>
      </div>
    </div>
    <div class="remembering">
      <div class="title">
        <h3>Remembering Philip T.R. Pugsley</h3>
      </div>
      <div class="content">
        <img src="../../assets/pc_img/rem_mobile.png" alt="">
        <p>Born in Montreal, Canada, Philip T.R. Pugsley was a leader of the JCI Movement at the local, national and
          international levels. At the 1951 JCI World Congress in Montreal, Canada, he was elected JCI President.</p>
        <p>During his travels, Pugsley witnessed organizations from around the world recognizing their outstanding
          members with trophies and other honorable awards, which lead to the idea for the JCI Senate.</p>
        <p>In 1952 at the seventh JCI World Congress, the JCI Senate was formed to officially recognize JCI members who
          displayed exemplary dedication to the JCI Mission. The JCI Senate is just one more way JCI members around the
          world can give back and financially assist positive change around the world.</p>
      </div>
    </div>
    <div class="senatorship">
      <h3>Give a JCI Senatorship</h3>
      <div class="content">
        <p>If there is an exceptional JCI member in your Local Organization, honor them with a JCI Senatorship. JCI
          Senatorships are a great way to show outstanding members their value to the organization. Complete the simple
          application process to recognize a JCI Senator in your Local Organization today. The JCI Senate is an honor
          that lasts a lifetime.</p>
        <span>Upon approval, JCI Senators receive a senate number, a personalized certificate, a membership card, a JCI
          Senate pin and additional programming options at JCI Events.</span>
        <div class="sen_btn">
          <div class="btn_com" @click="$router.push('/apply')">GIVE A JCI SENATORSHIP</div>
        </div>
      </div>
    </div>
    <pageFooter />
  </div>
</template>

<script>
  import pageFooter from "./components/Contact";
  import topNavBar from "./components/TopBar";

  export default {
    components: {
      topNavBar,
      pageFooter
    },
    methods: {
      toDetail() {
        window.open('https://jci.cc/web/#/senator')
      },
    },
  };
</script>

<style scoped lang='less'>
  .senate_page {
    .banner_content {
      position: relative;
      height: 5.14rem;
      background-image: url('../../assets/pc_img/jci_banner.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 88%;
    }

    .nar_content {
      position: absolute;
      color: #fff;
      top: 60%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 6.8rem;

      h2 {
        font-size: .6rem;
      }

      p {
        font-size: .2rem;
        margin-top: .3rem;
        font-weight: 300;
      }
    }

    .btn_com {
      width: 100%;
      height: .77rem;
      line-height: .77rem;
      background: #4266b0;
      border-radius: .39rem;
      margin-bottom: .2rem;
      text-align: center;
      color: #ffffff;
      font-size: .22rem;
    }

    h3 {
      font-size: .32rem;
      font-weight: 700;
      border-bottom: .04rem solid #EDBE38;
      width: 5.8rem;
      padding-bottom: .3rem;
      padding-left: .46rem;
      text-align: right;
    }

    .recognizes {
      position: relative;
      background-color: #F4F4F4;

      .content {
        padding-top: 1.2rem;
      }

      p {
        font-size: .3rem;
        padding: .6rem 1rem .88rem;
        text-align: left;
        line-height: .48rem;
      }

      .rec_btn {
        position: relative;
        padding: 0 1rem;
        padding-bottom: 2.6rem;
        z-index: 1;
      }

      h3 {
        padding-left: .68rem;
      }
    }

    .remembering {
      .title {
        display: flex;
        justify-content: flex-end;

        h3 {
          text-align: left;
          padding-left: 0;
          margin-top: 1rem;
        }
      }

      .content {
        padding: .6rem 1rem 1rem;

        img {
          width: 2.6rem;
          height: 2.45rem;
          margin-bottom: .6rem;
        }

        p {
          font-size: .28rem;
          text-align: left;
          margin-bottom: .1rem;
        }
      }
    }

    .senatorship {
      background-color: #f4f4f4;
      padding-top: 1.2rem;

      .content {
        padding: .6rem 1rem .87rem;
        font-size: .26rem;
        text-align: left;
        line-height: .48rem;
      }

      span {
        display: block;
        color: #999;
        margin-top: .3rem;
        margin-bottom: .8rem;
      }
    }
  }
</style>